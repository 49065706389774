import React, { useState, useEffect, useRef } from "react";
import SEO from "../components/seo/seo";
import { injectIntl } from "gatsby-plugin-react-intl";
import PackagingHome from "../components/packaging/PackagingHome";
import PackPicker from "../components/packaging/PackPicker";
import SelectedPack from "../components/packaging/SelectedPack";
import SideVerticalText from "../components/layout/sideVerticalText";
import BottomFactory from "../components/layout/BottomFactory";
import PackCarousel from "../components/carousel/PackCarousel";
import FadeIn from "../components/animations/FadeIn";
import { gsap } from "gsap";

const Packaging = ({ intl, location }) => {
	const [selectedPack, setselectedPack] = useState();

	const packSelectorHandler = event => {
		const packId = event.target.value;
		setselectedPack(packId);

	};

	useEffect(() => {
		if (location.hash.slice(1) !== selectedPack) {
			setselectedPack(location.hash.slice(1));
		}
	}, [location.hash, selectedPack]);

	useEffect(() => {
		const globalWindow = window || {};
		globalWindow.scrollTo(0,0)
	}, [selectedPack])

	let boxPack = useRef(null);
	
	useEffect(() => {
        gsap.fromTo(boxPack.current, {opacity: 0}, {duration: .5, opacity: 1, delay: 1.5})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div>
			<SEO title={intl.formatMessage({ id: "navbar.packaging" })} />
			<div className="productsPackPage">
				<div className="gridProductsPackSectionHome">
					<div className="boxProductsPackSideText">
						<FadeIn duration="2s" delay="0.5s"><SideVerticalText /></FadeIn>
					</div>

					<div className="boxProductsPackLeft ">
						<div className="productsSelectorContainer">
							{!selectedPack  ? (
								<PackagingHome />
							) : (
								<SelectedPack selectedPackProp={selectedPack} />
							)}
						</div>
					</div>

					<div className="boxProductsPackRight" ref={boxPack}>
						<PackPicker selectedPackProp={packSelectorHandler} />
					</div>

					<div className="boxProductsPackEmptyRight"></div>

					<div className="boxProductsPackBottomText">
						<BottomFactory />
					</div>
				</div>
			</div>
			<div className="ourPackageDiv">
				<p>{intl.formatMessage({ id: "packaging.ourPackaging" })}</p>
					<PackCarousel />
				</div>
		</div>
	);
};

export default injectIntl(Packaging);
