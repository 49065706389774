import React from "react";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-intl";
import { GatsbyImage } from "gatsby-plugin-image";

const PackPicker = (props) => {
	const data = useStaticQuery(graphql`{
  allPackagingDataJson {
    edges {
      node {
        id
        title
        linkTo
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 136, layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
    }
  }
  allPackagingModificationDataJson {
    edges {
      node {
        id
        title
        linkTo
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 136, layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
    }
  }
}
`);

	return (
        <div className="pickerContainer">
			<div className="pickers">
				{data.allPackagingDataJson.edges.map((item) => {
					return (
                        <div key={item.node.id} onClick={props.packSelectorHandler}>
							<Link value={item.node.id} to={item.node.linkTo}>
								<div className="pickerButton">
									<div className="pickedImageDiv">
										<GatsbyImage
                                            image={item.node.image.childImageSharp.gatsbyImageData}
                                            className="pickerProductImage"
                                            alt="sectionPic"
                                            loading="eager" />
										<div className="hoverContent">
											<FormattedMessage id={item.node.title} />
										</div>
									</div>
								</div>
							</Link>
						</div>
                    );
				})}
			</div>
			<div className="addPackagingPickerTitle">
				<FormattedMessage id="packaging.addPackagingWish" />
			</div>
			<div className="pickers">
				{data.allPackagingModificationDataJson.edges.map((item) => {
					return (
                        <div key={item.node.id}>
							<Link value={item.node.id} to={item.node.linkTo}>
								<div className="pickerButton">
									<div className="pickedImageDiv">
										<GatsbyImage
                                            image={item.node.image.childImageSharp.gatsbyImageData}
                                            className="pickerProductImage"
                                            alt="sectionPic"
                                            loading="eager" />
										<div className="hoverContent">
											<FormattedMessage id={item.node.title} />
										</div>
									</div>
								</div>
							</Link>
						</div>
                    );
				})}
			</div>
		</div>
    );
};

export default PackPicker;
