import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { injectIntl } from "gatsby-plugin-react-intl";
import { GatsbyImage } from "gatsby-plugin-image";

const PackagingHome = ({ intl }) => {
	const images = useStaticQuery(graphql`{
  packagingHomeImage: file(
    relativePath: {eq: "packagingPage/packagingHomeImage.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 700, quality: 100, layout: CONSTRAINED, placeholder: NONE)
    }
  }
}
`);

	return (
        <div className="prodPackHomeContainer">
			<p className="prodPackHomeTitle">
				{intl.formatMessage({ id: "navbar.packaging" })}
			</p>
			<GatsbyImage
                image={images.packagingHomeImage.childImageSharp.gatsbyImageData}
                className="packagingHomeImage"
                alt="packagingHomeImage"
                loading="eager" />
			<p className="prodPackHomeText">
				{intl.formatMessage({ id: "packaging.creativity" })}
			</p>
		</div>
    );
};

export default injectIntl(PackagingHome);
