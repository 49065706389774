import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionPanel from "@material-ui/core/Accordion";
import MuiAccordionPanelSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionPanelDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Accordion = withStyles({
	root: {
		boxShadow: "none",
		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
		"&$expanded": {
			margin: "auto",
		},
	},
	expanded: {},
})(MuiAccordionPanel);

const AccordionSummary = withStyles({
	root: {
		marginBottom: -1,
		minHeight: 56,
		"&$expanded": {
			minHeight: 56,
		},
	},
	content: {
		"&$expanded": {
			margin: "12px 0",
		},
	},
	expanded: {},
})(MuiAccordionPanelSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiAccordionPanelDetails);

const SelectedPack = (props) => {
	const data = useStaticQuery(graphql`{
  allPackagingDataJson {
    edges {
      node {
        imageSelected {
          childImageSharp {
            gatsbyImageData(width: 350, quality: 100, layout: CONSTRAINED, placeholder: NONE)
          }
        }
        id
        title
      }
    }
  }
  allPackagingModificationDataJson {
    edges {
      node {
        imageSelected {
          childImageSharp {
            gatsbyImageData(width: 250, quality: 100, layout: CONSTRAINED, placeholder: NONE)
          }
        }
        slug
        id
        title
      }
    }
  }
  allHorizontalMachinesDataJson {
    edges {
      node {
        image {
          childImageSharp {
            gatsbyImageData(width: 75, quality: 100, layout: FIXED, placeholder: NONE)
          }
        }
        subTitle
        goTo
        canDoPackaging
      }
    }
  }
  allVerticalMachinesDataJson {
    edges {
      node {
        image {
          childImageSharp {
            gatsbyImageData(width: 75, quality: 100, layout: FIXED, placeholder: NONE)
          }
        }
        title
        goTo
        canDoPackaging
      }
    }
  }
  allProcessLinesDataJson {
    edges {
      node {
        image {
          childImageSharp {
            gatsbyImageData(width: 150, quality: 100, layout: FIXED, placeholder: NONE)
          }
        }
        title
        goTo
        canDoPackaging
      }
    }
  }
  packagingHomeImage1: file(
    relativePath: {eq: "packagingPage/packagingHomeImage1.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 192, layout: CONSTRAINED, placeholder: NONE)
    }
  }
  packagingHomeImage2: file(
    relativePath: {eq: "packagingPage/packagingHomeImage2.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
    }
  }
  packagingLogo: file(relativePath: {eq: "packagingPage/packagingLogo.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 20, layout: FIXED, placeholder: NONE)
    }
  }
  horizontalStar: file(relativePath: {eq: "packagingPage/horizontalStar.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 10, layout: FIXED, placeholder: NONE)
    }
  }
  verticalStar: file(relativePath: {eq: "packagingPage/verticalStar.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 10, layout: FIXED, placeholder: NONE)
    }
  }
  processStar: file(relativePath: {eq: "packagingPage/processStar.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 10, layout: FIXED, placeholder: NONE)
    }
  }
}
`);
	const [expanded, setExpanded] = useState();

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const [loadedPack, setloadedPack] = useState({});

	const loadPack = () => {
		const loadedPackConst = {
			id: props.selectedPackProp,
		};
		setloadedPack(loadedPackConst);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {loadPack()}, [props.selectedPackProp]);

	return (
        <div className="selectedProductPack">
			<div className="selectedProductPackImageBox">
				{data.allPackagingDataJson.edges.map((item, index) => {
					const thisPack = loadedPack.id;
					const isTrue = item.node.id;
					return isTrue === thisPack ? (
						<GatsbyImage
                            image={item.node.imageSelected.childImageSharp.gatsbyImageData}
                            key={index}
                            className="selectedProductPackImage"
                            alt="sectionPic"
                            loading="eager" />
					) : null;
				})}
				{data.allPackagingModificationDataJson.edges.map((item, index) => {
					const thisPack = loadedPack.id;
					const isTrue = item.node.id;
					return isTrue === thisPack ? (
						<GatsbyImage
                            image={item.node.imageSelected.childImageSharp.gatsbyImageData}
                            key={index}
                            className="selectedProductPackImage"
                            alt="sectionPic"
                            loading="eager" />
					) : null;
				})}
			</div>

			<div className="selectedMainBox">
			    <div className="selectedProductTextBox">
					{data.allPackagingDataJson.edges.map((item, index) => {
						const thisPack = loadedPack.id;
						const isTrue = item.node.id;
						return isTrue === thisPack ? (
							<div className="selectedProductTextDiv" key={index}>
								<p className="selectedProductPackTitle">
									<FormattedMessage id={item.node.title} />
								</p>
								<p className="selectedCanDoTitle">
									<FormattedMessage id="packaging.machines" />
								</p>
							</div>
						) : null;
					})}
					{data.allPackagingModificationDataJson.edges.map((item, index) => {
						const thisPack = loadedPack.id;
						const isTrue = item.node.id;
						return isTrue === thisPack ? (
							<div className="selectedProductTextDiv" key={index}>
								<p className="selectedProductPackTitle">
									<FormattedMessage id={item.node.title} />
								</p>
								<p className="selectedCanDoTitle">
									<FormattedMessage id="packaging.machines" />
								</p>
							</div>
						) : null;
					})}
				</div>
				<div className="productPackAccordion">
					{data.allHorizontalMachinesDataJson.edges.some((item) => {
						const thisPack = props.selectedPackProp;
						const isTrue = item.node.canDoPackaging.includes(thisPack);
						if (isTrue) {
							return true;
						}
						return null;
					}) ? (
						<Accordion
							square
							expanded={expanded === "panel1"}
							onChange={handleChange("panel1")}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1d-content"
								id="panel1d-header"
							>
								<div className="selectedMachineCircleBox">
									<div className="selectedProductHorizontalMachineTitlesCircle"></div>
									<p>
										<FormattedMessage id="machineSelection.horizontal" />
									</p>
								</div>
							</AccordionSummary>

							<AccordionDetails>
								<div className="selectedJsonBox">
									{data.allHorizontalMachinesDataJson.edges.map(
										(item, index) => {
											const thisPack = props.selectedPackProp;
											const isTrue = item.node.canDoPackaging.includes(
												thisPack
											);
											if (isTrue)
												return (
                                                    <li key={index} className="selectedJsonLists">
														<Link to={item.node.goTo}>
															<GatsbyImage
                                                                image={item.node.image.childImageSharp.gatsbyImageData}
                                                                className="selectedJsonImage"
                                                                alt="horizontalImages"
                                                                loading="eager" />
															<p className="selectedJsonTitle">
																<FormattedMessage id={item.node.subTitle} />
															</p>
														</Link>
													</li>
                                                );
											return null;
										}
									)}
								</div>
							</AccordionDetails>
						</Accordion>
					) : (
						" "
					)}
				</div>

				<div className="productPackAccordion">
					{data.allVerticalMachinesDataJson.edges.some((item) => {
						const thisPack = props.selectedPackProp;
						const isTrue = item.node.canDoPackaging.includes(thisPack);
						if (isTrue) {
							return true;
						}
						return null;
					}) ? (
						<Accordion
							square
							expanded={expanded === "panel2"}
							onChange={handleChange("panel2")}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel2d-content"
								id="panel2d-header"
							>
								<div className="selectedMachineCircleBox">
									<div className="selectedProductVerticalalMachineTitlesCircle"></div>
									<p>
										<FormattedMessage id="machineSelection.vertical" />
									</p>
								</div>
							</AccordionSummary>

							<AccordionDetails>
								<div className="selectedJsonBox">
									{data.allVerticalMachinesDataJson.edges.map((item, index) => {
										const thisPack = props.selectedPackProp;
										const isTrue = item.node.canDoPackaging.includes(thisPack);
										if (isTrue)
											return (
                                                <li key={index} className="selectedJsonLists">
													<Link to={item.node.goTo}>
														<GatsbyImage
                                                            image={item.node.image.childImageSharp.gatsbyImageData}
                                                            className="selectedJsonImage"
                                                            alt="horizontalImages"
                                                            loading="eager" />
														<p className="selectedJsonTitle">
															{item.node.title}
														</p>
													</Link>
												</li>
                                            );
										return null;
									})}
								</div>
							</AccordionDetails>
						</Accordion>
					) : (
						" "
					)}
				</div>
				<div className="productPackAccordion">
					{data.allProcessLinesDataJson.edges.some((item) => {
						const thisPack = props.selectedPackProp;
						const isTrue = item.node.canDoPackaging.includes(thisPack);
						if (isTrue) {
							return true;
						}
						return null;
					}) ? (
						<Accordion
							square
							expanded={expanded === "panel3"}
							onChange={handleChange("panel3")}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel3d-content"
								id="panel3d-header"
							>
								<div className="selectedMachineCircleBox">
									<div className="selectedProductProcessLinesMachineTitlesCircle"></div>
									<p>
										<FormattedMessage id="machineSelection.processLines" />
									</p>
								</div>
							</AccordionSummary>

							<AccordionDetails>
								<div className="selectedJsonBox">
									{data.allProcessLinesDataJson.edges.map((item, index) => {
										const thisPack = props.selectedPackProp;
										const isTrue = item.node.canDoPackaging.includes(thisPack);
										if (isTrue)
											return (
                                                <li key={index} className="selectedJsonLists">
													<Link to={item.node.goTo}>
														<GatsbyImage
                                                            image={item.node.image.childImageSharp.gatsbyImageData}
                                                            className="selectedJsonImage"
                                                            alt="horizontalImages"
                                                            loading="eager" />
														<p className="selectedJsonTitle">
															{item.node.title}
														</p>
													</Link>
												</li>
                                            );
										return null;
									})}
								</div>
							</AccordionDetails>
						</Accordion>
					) : (
						" "
					)}
				</div>
			</div>
		</div>
    );
};
export default injectIntl(SelectedPack);
